import { Box, Button, Typography } from "@material-ui/core";
import { useTranslation } from "i18n";
import Link from "next/link";

const SplashError = ({ label }) => {
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      flexGrow={1}
      height="100%"
    >
      <Box>
        <Typography color="textSecondary" variant="h3">
          Ohh f**k!
        </Typography>
        <Typography paragraph color="textSecondary" variant="h4">
          {label}
        </Typography>
        <Typography paragraph variant="h1" color="primary">
          :(
        </Typography>
        <Link href="/">
          <Button variant="outlined">
            {t("TO_HOME")}
          </Button>
        </Link>
      </Box>
    </Box>
  );
};

export default SplashError;
